import { createSlice } from '@reduxjs/toolkit';
import { RootState } from './indexStore';
import type { PayloadAction } from '@reduxjs/toolkit';

interface InitState {
	menuActive: string;
}

const initialState: InitState = {
	menuActive: 'false',
};

// 创建Action
export const menuSlice = createSlice({
	name: 'menu', // 命名空间，在调用action的时候会默认的设置为action的前缀
	// 初始值
	initialState,
	// 这里的属性会自动的导出为actions，在组件中可以直接通过dispatch进行触发
	reducers: {
		actionMenuActive(state, action: PayloadAction<string>) {
			state.menuActive = action.payload; // 内置了immutable，因此可以直接通过赋值的方式修改
		},
	},
});
// 导出actions，在触发修改状态时用到
export const { actionMenuActive } = menuSlice.actions;

export const stateMenuActive = (state: RootState) => state.menu.menuActive;

export default menuSlice.reducer; // 导出reducer，在创建store时使用到
