import { createSlice } from '@reduxjs/toolkit';
import { RootState } from './indexStore';
import type { PayloadAction } from '@reduxjs/toolkit';
import { InterfaceVideoItem } from '@/types/interfaceVideo';

const initialState: InterfaceVideoItem = {
	id: '',
	title: '',
	source: '',
	cover: '',
	views: 0,
	countComments: 0,
	recommends: [],
	countSave: 0,
	likes: 0,
	desc: '',
	createTime: '',
};

// 创建Action
export const videoSlice = createSlice({
	name: 'video', // 命名空间，在调用action的时候会默认的设置为action的前缀
	// 初始值
	initialState: {
		currentVideoItem: initialState,
	},
	// 这里的属性会自动的导出为actions，在组件中可以直接通过dispatch进行触发
	reducers: {
		actionCurrentVideo(state, action: PayloadAction<InterfaceVideoItem>) {
			state.currentVideoItem = action.payload; // 内置了immutable，因此可以直接通过赋值的方式修改
		},
	},
});
// 导出actions，在触发修改状态时用到
export const { actionCurrentVideo } = videoSlice.actions;

export const stateCurrentVideo = (state: RootState) =>
	state.video.currentVideoItem;

export default videoSlice.reducer; // 导出reducer，在创建store时使用到
