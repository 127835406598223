import { createSlice } from '@reduxjs/toolkit';
import { RootState } from './indexStore';
import type { PayloadAction } from '@reduxjs/toolkit';
import { InterfaceTag } from '@/types/tag.interface';

// 创建Action
export const globalSlice = createSlice({
	name: 'global', // 命名空间，在调用action的时候会默认的设置为action的前缀
	// 初始值
	initialState: {
		globalLoading: false,
		tags: [] as InterfaceTag[],
	},
	// 这里的属性会自动的导出为actions，在组件中可以直接通过dispatch进行触发
	reducers: {
		actionGlobalLoading(state, action: PayloadAction<boolean>) {
			state.globalLoading = action.payload; // 内置了immutable，因此可以直接通过赋值的方式修改
		},
		actionGlobalTags(state, action: PayloadAction<InterfaceTag[]>) {
			state.tags = action.payload;
		},
	},
});
// 导出actions，在触发修改状态时用到
export const { actionGlobalLoading, actionGlobalTags } = globalSlice.actions;

export const stateGlobalLoading = (state: RootState) =>
	state.global.globalLoading;

export const stateTags = (state: RootState) => state.global.tags;

export default globalSlice.reducer; // 导出reducer，在创建store时使用到
