/*
 * 权限相关接口
 */
import { AxiosResponse } from 'axios';
import { get, post } from '../request';
import {
	RegisterParams,
	LoginParams,
	CodeLoginParams,
	GetCodeParams,
	ResInterface,
} from '@/types/api.interface';

// 注册
const RegisterPath = '/api/v1/user/create';
type ResDataRegisterInterface = AxiosResponse<ResInterface>;
export const register = (
	data: RegisterParams
): Promise<ResDataRegisterInterface> => post(RegisterPath, data);

// 获取验证码
const GetCodePath = '/api/v1/auth/code';
// 返回数据类型
type ResDataGetCodeInterface = AxiosResponse<ResInterface>;
export const getCode = (
	data: GetCodeParams
): Promise<ResDataGetCodeInterface> => post(GetCodePath, data);

// 验证码登陆
const CodeLoginPath = '/api/v1/auth/codeLogin';
export const codeLogin = (data: CodeLoginParams) => post(CodeLoginPath, data);

// 普通邮箱登陆
const LoginPath = '/api/v1/auth/login';
export const login = (data: LoginParams) => post(LoginPath, data);

// 校验用户登陆状态
const ValidateLoginStatusPath = '/api/v1/auth/loginStatus';
export const validateLoginStatus = () => get(ValidateLoginStatusPath);

// 验证码登陆
const CodeVerifyPath = '/api/v1/auth/verifyCode';
export const codeVerify = (data: CodeLoginParams) => post(CodeVerifyPath, data);

// 获取腾讯云临时密钥
const GetCosSignPath = '/api/v1/auth/cosSign';
export const getCosSign = () => get(GetCosSignPath);
