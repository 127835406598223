import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../indexStore';
import type { PayloadAction } from '@reduxjs/toolkit';
import { InterfaceDataContent } from '@/types/comment/timeline';

interface InitState {
	projection: mapboxgl.Projection | undefined; // 地图投影方式
	searchStatus: boolean;
	language: string; // 地图语言
	visibleRoad: boolean; // 是否展示道路
	visiblePlaceLabel: boolean; // 是否展示地点名称
	styleId: string; // 图层样式
	currentSwiperItem: InterfaceDataContent | null;
	selectedTags: string[]; // 当前选中的tag
}

const initialState: InitState = {
	projection: {
		name: 'globe',
		// | 'albers'
		// | 'equalEarth'
		// | 'equirectangular'
		// | 'lambertConformalConic'
		// | 'mercator'
		// | 'naturalEarth' // 自然投影
		// | 'winkelTripel'
		// | 'globe';
		center: [102, 36],
		// parallels: [number, number];
	},
	searchStatus: false,
	language: 'zh-Hans',
	visibleRoad: false,
	visiblePlaceLabel: true,
	styleId: 'outdoor',
	currentSwiperItem: null,
	selectedTags: [],
};

// 创建Action
export const mapIndexSlice = createSlice({
	name: 'map', // 命名空间，在调用action的时候会默认的设置为action的前缀
	// 初始值
	initialState,
	// 这里的属性会自动的导出为actions，在组件中可以直接通过dispatch进行触发
	reducers: {
		actionMapProjection(
			state,
			action: PayloadAction<mapboxgl.Projection | undefined>
		) {
			state.projection = action.payload;
		},
		actionMapSearch(state, action: PayloadAction<boolean>) {
			state.searchStatus = action.payload; // 内置了immutable，因此可以直接通过赋值的方式修改
		},
		actionMapLanguage(state, action: PayloadAction<string>) {
			state.language = action.payload;
		},
		actionMapRoad(state, action: PayloadAction<boolean>) {
			state.visibleRoad = action.payload;
		},
		actionMapPlaceLabel(state, action: PayloadAction<boolean>) {
			state.visiblePlaceLabel = action.payload;
		},
		actionMapStyleId(state, action: PayloadAction<string>) {
			state.styleId = action.payload;
		},
		actionCurrentDataScal(
			state,
			action: PayloadAction<InterfaceDataContent | null>
		) {
			state.currentSwiperItem = action.payload;
		},
		actionMapSelectTags(state, action: PayloadAction<string[]>) {
			state.selectedTags = action.payload;
		},
	},
});
// 导出actions，在触发修改状态时用到
export const {
	actionMapProjection,
	actionMapSearch,
	actionMapLanguage,
	actionMapRoad,
	actionMapPlaceLabel,
	actionMapStyleId,
	actionCurrentDataScal,
	actionMapSelectTags,
} = mapIndexSlice.actions;

export const stateMapProjection = (state: RootState) => state.map.projection;

export const stateMapSearchStatus = (state: RootState) =>
	state.map.searchStatus;

export const stateMapLanguage = (state: RootState) => state.map.language;

export const stateMapVisibleRoad = (state: RootState) => state.map.visibleRoad;

export const stateMapVisiblePlaceLabel = (state: RootState) =>
	state.map.visiblePlaceLabel;

export const stateMapStyleId = (state: RootState) => state.map.styleId;

export const stateCurrentDataScal = (state: RootState) =>
	state.map.currentSwiperItem;

export const stateMapSelectTags = (state: RootState) => state.map.selectedTags;

export default mapIndexSlice.reducer; // 导出reducer，在创建store时使用到
