/* 新建一个 cos.js，导出 cos 实例 */
import COS from 'cos-js-sdk-v5';
import { getCosSign } from '@/api/auth/auth';
import { ResDataInterface } from '@/types/api.interface';
import { AxiosResponse } from 'axios';

type DataCosInterface = {
	credentials: {
		tmpSecretId: string;
		tmpSecretKey: string;
		sessionToken: string;
	};
	startTime: number;
	expiredTime: number;
};

export const cos = new COS({
	// getAuthorization 必选参数
	getAuthorization(options, callback) {
		// 异步获取临时密钥
		getCosSign()
			.then(
				(res: AxiosResponse<ResDataInterface<DataCosInterface>>) => res.data
			)
			.then((res: ResDataInterface<DataCosInterface>) => {
				if (res?.code === 0) {
					const { credentials } = res.data;

					callback({
						TmpSecretId: credentials.tmpSecretId,
						TmpSecretKey: credentials.tmpSecretKey,
						SecurityToken: credentials.sessionToken,
						// 建议返回服务器时间作为签名的开始时间，避免用户浏览器本地时间偏差过大导致签名错误
						StartTime: res.data?.startTime, // 时间戳，单位秒，如：1580000000
						ExpiredTime: res.data?.expiredTime, // 时间戳，单位秒，如：1580000000
					});
				}
			});
	},
});

// 获取cos对象访问 URL
// 腾讯云参考：https://cloud.tencent.com/document/product/436/57420
export const getImageUrl = async (key: string): Promise<string> => {
	try {
		const promiseUrl = new Promise<string>((resolve, reject) => {
			cos.getObjectUrl(
				{
					Bucket: 'avatars-1305484239',
					Region: 'ap-nanjing',
					Key: key,
				},
				(err, data) => {
					if (err) {
						reject(err);
					} else {
						resolve(data.Url || '');
					}
				}
			);
		});

		const url = await promiseUrl;
		return url;
	} catch (error) {
		console.error('Error fetching image URL:', error);
		throw error;
	}
};
