import { Header } from './header/indexHeader';
import { Outlet } from 'react-router-dom';
import { useValidateLogin } from '@/hooks';
import './layout.less';

export const Layout = () => {
	// useValidateLogin(); // 登陆校验
	return (
		<>
			<Header></Header>
			<div className="contentContainer">
				<Outlet></Outlet>
			</div>
		</>
	);
};
