import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Avatar, Badge, Space, Popover, Button } from 'antd';
import { LogoutOutlined, SettingOutlined } from '@ant-design/icons';
import Cookies from 'js-cookie';
import { HeaderMenu } from './menu';
// import { LogoIcon } from '@/components/Logo';
import logo from '@/assets/logo.png';
import { useAppSelector, useAppDispatch } from '@/hooks';
import { actionMenuActive } from '@/store/storeMenu';
import { actionUserInfo } from '@/store/storeUser';
import { getCountCommentsAPI, getCountRecommendsAPI } from '@/api/commentApi';
import { getMessageCountUnread } from '@/api/commonApi';

import Icon from '@ant-design/icons';
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';

import './header.less';
import { getImageUrl } from '@/utils/upload';

export const Header = () => {
	const stateUserInfo = useAppSelector((state) => state.user.userInfo);
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const [countMessageUnread, setCountMessageUnread] = useState<number>(0);
	const [profileUrl, setProfileUrl] = useState<string>('');

	useEffect(() => {
		// 获取用户未读消息数
		if (stateUserInfo.id) {
			getMessageCountUnread()
				.then((res) => res.data)
				.then((res) => {
					const { code, data } = res;
					if (code === 0) {
						setCountMessageUnread(data.count);
					}
				});
		}

		// 获取用户头像
		if (stateUserInfo.profile) {
			getImageUrl(stateUserInfo.profile).then((url) => {
				setProfileUrl(url);
			});
		}
	}, [stateUserInfo.id, stateUserInfo.profile]);

	const ContentPopover = () => {
		const [countComments, setCountComments] = useState<number>(0);
		const [countRecommends, setCountRecommends] = useState<number>(0);
		const [editSvgColor, setEditSvgColor] = useState('#707070');
		const EditSvgComponent = () => (
			<svg
				width="16px"
				height="16px"
				viewBox="0 0 1024 1024"
				version="1.1"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					fill={editSvgColor}
					d="M862.709333 116.042667a32 32 0 1 1 45.248 45.248L455.445333 613.813333a32 32 0 1 1-45.258666-45.258666L862.709333 116.053333zM853.333333 448a32 32 0 0 1 64 0v352c0 64.8-52.533333 117.333333-117.333333 117.333333H224c-64.8 0-117.333333-52.533333-117.333333-117.333333V224c0-64.8 52.533333-117.333333 117.333333-117.333333h341.333333a32 32 0 0 1 0 64H224a53.333333 53.333333 0 0 0-53.333333 53.333333v576a53.333333 53.333333 0 0 0 53.333333 53.333333h576a53.333333 53.333333 0 0 0 53.333333-53.333333V448z"
				/>
			</svg>
		);

		const EditIcon = (props: Partial<CustomIconComponentProps>) => (
			<Icon component={EditSvgComponent} {...props} />
		);

		const handleTouchBtn = (
			e: React.MouseEvent<HTMLButtonElement, MouseEvent>
		) => {
			const { type } = e;
			type === 'mouseenter'
				? setEditSvgColor('#fa8c16')
				: setEditSvgColor('#707070');
		};

		const handleClickMy = () => {
			navigate('/user/userInfo');
		};

		const handleLogout = () => {
			Cookies.remove('token');
			dispatch(actionMenuActive(''));
			dispatch(
				actionUserInfo({
					id: '',
					name: '',
					email: '',
					phoneNumber: '',
					profile: '',
					likedBooks: [],
					likedVideos: [],
					likedComments: [],
					saveBooks: [],
					saveVideos: [],
				})
			);
			navigate('/');
		};

		const handleLinkToUserInfo = (hash: string) => {
			navigate(`/user/userInfo/#${hash}`);
		};

		const handleClickRecommend = () => {
			navigate('/user/recommend');
		};

		useEffect(() => {
			getCountCommentsAPI()
				.then((res) => res.data)
				.then((res) => {
					const { code, data } = res;
					if (code === 0) {
						setCountComments(data.count);
					}
				})
				.catch((err) => {
					console.log(err);
				});

			getCountRecommendsAPI()
				.then((res) => res.data)
				.then((res) => {
					const { code, data } = res;
					if (code === 0) {
						setCountRecommends(data.count);
					}
				})
				.catch((err) => {
					console.log(err);
				});
		}, []);

		return (
			<div className="profileList">
				{(stateUserInfo.name ||
					stateUserInfo.email ||
					stateUserInfo.phoneNumber) && (
					<div className="userName" onClick={handleClickMy}>
						{stateUserInfo.name ||
							stateUserInfo.email ||
							stateUserInfo.phoneNumber}
					</div>
				)}
				<div className="write">
					<Button
						style={{ color: editSvgColor }}
						icon={<EditIcon />}
						onMouseEnter={(
							e: React.MouseEvent<HTMLButtonElement, MouseEvent>
						) => {
							handleTouchBtn(e);
						}}
						onMouseLeave={(
							e: React.MouseEvent<HTMLButtonElement, MouseEvent>
						) => handleTouchBtn(e)}
						onClick={handleClickRecommend}
					>
						我要推荐好书
					</Button>
				</div>
				<div className="profileListItem favoritesCollects">
					<span className="item" onClick={() => handleLinkToUserInfo('like')}>
						{`点赞 ${
							stateUserInfo.likedBooks?.length ||
							0 + stateUserInfo.likedVideos?.length ||
							0 + stateUserInfo.likedComments?.length ||
							0
						}`}
					</span>
					<span className="item" onClick={() => handleLinkToUserInfo('save')}>
						{`收藏 ${
							stateUserInfo.saveBooks?.length ||
							0 + stateUserInfo.saveVideos?.length ||
							0
						}`}
					</span>
				</div>
				<div className="profileListItem">
					<span
						className="item"
						onClick={() => handleLinkToUserInfo('recommend')}
					>
						{`推荐 ${countRecommends}`}
					</span>
					<span
						className="item"
						onClick={() => handleLinkToUserInfo('comment')}
						style={{ lineHeight: '20px' }}
					>
						{`评论 ${countComments}`}
					</span>
				</div>
				<div className="profileListItem messages">
					<Badge count={countMessageUnread} size="small">
						<span
							className="item"
							onClick={() => handleLinkToUserInfo('message')}
						>
							消息
						</span>
					</Badge>
				</div>
				<div className="profileListItem logout">
					<div className="item" onClick={() => handleLinkToUserInfo('info')}>
						<span>设置</span>
						<SettingOutlined />
					</div>
					<div className="item" onClick={handleLogout}>
						<span>退出</span>
						<LogoutOutlined />
					</div>
				</div>
			</div>
		);
	};

	const getPopupContainer = () => {
		return document.getElementById('profile') || document.body;
	};

	const handleLogin = () => {
		navigate('/user/login');
	};

	const handleBackHome = () => {
		navigate('/');
		dispatch(actionMenuActive(''));
	};

	return (
		<div className="headerContainer">
			<div className="logo" onClick={handleBackHome}>
				{/* <LogoIcon></LogoIcon> */}
				<img src={logo}></img>
				<div className="logoText">有意视 ｜ 把知识串起来</div>
			</div>
			<div className="menuContainer">{/* <HeaderMenu></HeaderMenu> */}</div>
			{/* <div>购物车</div> */}
			<div className="profile" id="profile">
				{!stateUserInfo.name &&
				!stateUserInfo.email &&
				!stateUserInfo.phoneNumber ? (
					<div className="loginOrRegisterContainer" onClick={handleLogin}>
						登陆/注册
					</div>
				) : (
					<Space size={24}>
						<Popover
							className="popoverProfile"
							placement="bottom"
							// getPopupContainer={() => getPopupContainer()}
							content={ContentPopover}
							trigger="click"
						>
							<Badge className="avatarContainer" dot={countMessageUnread > 0}>
								<Avatar
									size={{ xs: 42 }}
									src={profileUrl}
								/>
							</Badge>
							</Popover>
					</Space>
				)}
			</div>
		</div>
	);
};
