import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { validateLoginStatus } from '@/api/auth/auth';
import { ResValidateLoginInterface } from '@/types/apiUser.interface';
import { RatedInterface } from '@/types/common.interface';
import { AxiosResponse } from 'axios';
import { actionUserInfo, actionRated } from '@/store/storeUser';
import { useDispatch, useSelector } from 'react-redux';
import type { TypedUseSelectorHook } from 'react-redux';
import type { RootState, AppDispatch } from './store/indexStore';
import Cookies from 'js-cookie';

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

// 验证用户登陆状态
export const useValidateLogin = () => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	// 校验用户登陆状态
	useEffect(() => {
		validateLoginStatus()
			.then((res: AxiosResponse<ResValidateLoginInterface>) => res.data)
			.then((res: ResValidateLoginInterface) => {
				if (res.code === 0) {
					const { id, name, email, phoneNumber, rated } = res.data;
					if (id) {
						dispatch(actionUserInfo(res?.data));
						dispatch(actionRated(rated || []));
					} else {
						Cookies.remove('token');
						dispatch(
							actionUserInfo({
								id: '',
								name: '',
								email: '',
								profile: '',
								phoneNumber: '',
								likedVideos: [],
								likedBooks: [],
								likedComments: [],
								saveVideos: [],
								saveBooks: [],
							})
						);
						// localhost不跳转到登录页面
						if (window.location.hostname !== 'localhost') {
							navigate('/login');
						}
					}
				}
			})
			.catch((error) => {
				console.log(error);
				navigate('/user/login');
			});
	}, []);
};

/**
 * 跳转到登录页面
 */
export const useJumpLogin = () => {
	const navigate = useNavigate();
	Cookies.remove('token');
	navigate('/login');
};
