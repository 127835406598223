import React, { LazyExoticComponent } from 'react';
import { Spin } from 'antd';

export const LazyImportComponent = (props: {
	lazyChildren: LazyExoticComponent<() => JSX.Element>;
}) => {
	return (
		<React.Suspense fallback={<Spin fullscreen />}>
			<props.lazyChildren />
		</React.Suspense>
	);
};
