// 封装axios
import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { jumpLogin } from '@/utils';
import Cookies from 'js-cookie';
import { message } from 'antd';

export const createAxiosByInterceptors = (
	config?: AxiosRequestConfig
): AxiosInstance => {
	const instance = axios.create({
		timeout: 15 * 1000, //超时配置,15s
		withCredentials: true, //跨域携带cookie
		...config, // 自定义配置覆盖基本配置
	});

	// 添加请求拦截器,请求之前统一处理config信息
	instance.interceptors.request.use(
		function (config: any) {
			// 在发送请求之前做些什么
			config.headers.Authorization = Cookies.get('token');
			return config;
		},
		function (error: any) {
			// 对请求错误做些什么
			return Promise.reject(error);
		}
	);

	// 添加响应拦截器,统一处理某些响应
	instance.interceptors.response.use(
		function (response: AxiosResponse) {
			// 对响应数据做点什么
			// console.log('response拦截:', response);

			// 处理http状态码
			const { status } = response;
			switch (status) {
				case 200:
				case 201:
					break;
				case 401:
					jumpLogin();
					break;
				case 403:
					console.log('登录过期，请重新登录');
					jumpLogin();
					break;
				case 404:
					console.log('请求资源不存在');
					break;
				default:
					console.log('请求失败');
					break;
			}

			// 接口返回的数据
			const { code, httpCode, message } = response.data;

			if (httpCode === 401) {
				jumpLogin();
				return Promise.reject(response.data);
			} else if (httpCode === 403) {
				message.warning(`无权限：${message}`);
				jumpLogin();
				return Promise.reject(response.data);
			}

			if (code === 0) {
				return response;
			} else {
				return Promise.reject(response.data);
			}
		},
		function (error: any) {
			// 对响应错误做点什么
			console.log('error-response:', error.response);
			console.log('error-config:', error.config);
			console.log('error-request:', error.request);
			if (error.response) {
				if (error.response.status === 401 || error.response.status === 403) {
					jumpLogin();
				}
			}
			console.log(error?.response?.data?.message || '服务端异常');
			return Promise.reject(error);
		}
	);

	return instance;
};

// const dispatch = useAppDispatch();

// let requestNum = 0;
// 创建全局加载
// export const addLoading = () => {
// 	// 增加loading 如果pending请求数量等于1，弹出loading, 防止重复弹出
// 	requestNum++;
// 	if (requestNum === 1) {
// 		dispatch(actionGlobalLoading(true));
// 	}
// };
// 取消全局加载
// export const cancelLoading = () => {
// 	// 取消loading 如果pending请求数量等于0，关闭loading
// 	requestNum--;
// 	if (requestNum === 0) dispatch(actionGlobalLoading(false));
// };

const axiosInstance = createAxiosByInterceptors({
	// baseURL: `${baseURL}:${basePort}`,
	timeout: 15000,
});

export const get = (url: string, config?: {}) => axiosInstance.get(url, config);

export const post = async (url: string, data?: {}, config?: {}) => {
	// addLoading();
	const res = await axiosInstance.post(url, data, config);
	// cancelLoading();
	if (res.data.code === 0) {
		return res;
	} else {
		return Promise.reject(res.data);
	}
};

export const put = (url: string, data: {}, config?: {}) =>
	axiosInstance.put(url, data, config);

export const del = (url: string, config?: {}) =>
	axiosInstance.delete(url, config);
