// 地图管理的数据
// 当前正在播放的地图数据
import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../indexStore';
import type { PayloadAction } from '@reduxjs/toolkit';
import { InterfaceGeoJsonPoint } from '@/types/interfaceGeoJsonPoint';

interface InitState {
	marksData: {
		marks: InterfaceGeoJsonPoint[]; //点位信息
	};
}

const initialState: InitState = {
	marksData: {
		marks: [
			{
				type: 'geojson',
				data: {
					type: 'FeatureCollection',
					features: [
						{
							type: 'Feature',
							properties: {
								title: '点位1', // 定义一些其他的属性
							},
							geometry: {
								// 几何信息
								type: 'Point',
								coordinates: [
									// 坐标
									-34.3441341, 56.43134144,
								],
							},
						},
					],
				},
			},
			{
				type: 'geojson',
				data: {
					type: 'FeatureCollection',
					features: [
						{
							type: 'Feature',
							properties: {
								title: '点位2', // 定义一些其他的属性
							},
							geometry: {
								// 几何信息
								type: 'Point',
								coordinates: [
									// 坐标
									-34.44343434, 58.34314314,
								],
							},
						},
					],
				},
			},
		],
	},
};

// 创建Action
export const mapPlaySlice = createSlice({
	name: 'mapData', // 命名空间，在调用action的时候会默认的设置为action的前缀
	// 初始值
	initialState,
	// 这里的属性会自动的导出为actions，在组件中可以直接通过dispatch进行触发
	reducers: {
		actionPlayData(state, action: PayloadAction<any>) {
			state.marksData = action.payload; // 内置了immutable，因此可以直接通过赋值的方式修改
		},
	},
});
// 导出actions，在触发修改状态时用到
export const { actionPlayData } = mapPlaySlice.actions;

export const statePlayData = (state: RootState) => state.mapData.marksData;

export default mapPlaySlice.reducer; // 导出reducer，在创建store时使用到
