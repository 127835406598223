import { createSlice } from '@reduxjs/toolkit';
import { TypeUserInfo } from '@/types/apiUser.interface';
import { RecommendContentInterface } from '@/types/common.interface';
import { ResCommentInterface } from '@/types/comment/comment.interface';

import { InterfaceTag } from '@/types/tag.interface';
import { RatedInterface } from '@/types/common.interface';
import { RootState } from './indexStore';
import type { PayloadAction } from '@reduxjs/toolkit';

interface InitState {
	userInfo: TypeUserInfo;
	recommends: RecommendContentInterface[]; // 推荐用户列表
	comments: ResCommentInterface[];
	tags: InterfaceTag[];
	rated: RatedInterface[];
}

const initialState: InitState = {
	userInfo: {
		id: '',
		name: '',
		email: '',
		profile: '',
		phoneNumber: '',
		likedVideos: [],
		likedBooks: [],
		likedComments: [],
		saveVideos: [],
		saveBooks: [],
		roles: [],
	},
	rated: [],
	recommends: [], // 推荐列表
	comments: [], // 评论列表
	tags: [],
};

// 创建Action
export const userSlice = createSlice({
	name: 'user', // 命名空间，在调用action的时候会默认的设置为action的前缀
	// 初始值
	initialState,
	// 这里的属性会自动的导出为actions，在组件中可以直接通过dispatch进行触发
	reducers: {
		actionUserInfo(state, action: PayloadAction<TypeUserInfo>) {
			state.userInfo = action.payload; // 内置了immutable，因此可以直接通过赋值的方式修改
		},
		actionRecommends(
			state,
			action: PayloadAction<RecommendContentInterface[]>
		) {
			state.recommends = action.payload;
		},
		actionCommends(state, action: PayloadAction<ResCommentInterface[]>) {
			state.comments = action.payload;
		},
		actionRated(state, action: PayloadAction<RatedInterface[]>) {
			state.rated = action.payload;
		},
	},
});
// 导出actions，在触发修改状态时用到
export const { actionUserInfo, actionRecommends, actionCommends, actionRated } =
	userSlice.actions;

export const stateUserInfo = (state: RootState) => state.user.userInfo;
export const stateRecommends = (state: RootState) => state.user.recommends;
export const stateCommends = (state: RootState) => state.user.comments;
export const stateRated = (state: RootState) => state.user.rated;
export const stateTags = (state: RootState) => state.user.tags;

export default userSlice.reducer; // 导出reducer，在创建store时使用到
