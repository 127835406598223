/*
 * 通用接口
 */
import { AxiosResponse } from 'axios';
import { get, post, put } from './request';
import {
	GlobalGetPagesParams,
	ResDataInterface,
	ResInterface,
} from '@/types/api.interface';
import {
	ReqParamsLikedInterface,
	ResDataLikedListInterface,
	ResDataSaveInterface,
	ReqParamsLikeSaveListInterface,
	InterfaceRecommendItem,
	ResDataRecommendListInterface,
	ResDataMessageListInterface,
} from '@/types/common.interface';
import { InterfaceTag } from '@/types/tag.interface';

// 点赞
const LikedPath = '/api/v1/user/liked';
type LikedResInterface = AxiosResponse<ResDataInterface<string[]>>;
export const likedAPI = (
	data: ReqParamsLikedInterface
): Promise<LikedResInterface> => post(LikedPath, data);

// 获取点赞\收藏列表
const GetLikedListPath = '/api/v1/user/likeSave/list';
type GetLikedListResInterface = AxiosResponse<ResDataLikedListInterface>;
export const getLikeSaveListAPI = (
	data: ReqParamsLikeSaveListInterface
): Promise<GetLikedListResInterface> => post(GetLikedListPath, data);

// 获取消息列表
const GetMessageListPath = '/api/v1/user/message/list';
type GetMessageListResInterface = AxiosResponse<ResDataMessageListInterface>;
export const getMessageListAPI = (
	data: GlobalGetPagesParams
): Promise<GetMessageListResInterface> => post(GetMessageListPath, data);

// 获取未读消息数量
const GetCommentCountPath = '/api/v1/user/message/countUnread';
type GetCommentCountResInterface = AxiosResponse<
	ResDataInterface<{ count: number }>
>;
export const getMessageCountUnread = (): Promise<GetCommentCountResInterface> =>
	get(GetCommentCountPath);

// 修改消息为已读
const ReadMessagePath = '/api/v1/user/message';
type ReadMessageResInterface = AxiosResponse<ResInterface>;
export const putMessageStatusAPI = (
	id: string
): Promise<ReadMessageResInterface> => put(`${ReadMessagePath}/${id}`, {});

// 收藏
const SavePath = '/api/v1/user/save';
type SaveResInterface = AxiosResponse<ResDataInterface<string[]>>;
export const saveAPI = (
	data: ReqParamsLikedInterface
): Promise<SaveResInterface> => post(SavePath, data);

// 推荐
const RecommendPath = '/api/v1/user/recommend';
type RecommendResInterface = AxiosResponse<ResInterface>;
export const recommendApi = (
	data: InterfaceRecommendItem
): Promise<RecommendResInterface> => post(RecommendPath, data);

// 获取某个推荐内容
const GetRecommendPath = '/api/v1/user/recommend';
type GetRecommendResInterface = AxiosResponse<
	ResDataInterface<InterfaceRecommendItem>
>;
export const getRecommend = (id: string): Promise<GetRecommendResInterface> =>
	get(`${GetRecommendPath}/${id}`);

// 修改某个推荐内容
const DeleteRecommendPath = '/api/v1/user/recommend';
type DeleteRecommendResInterface = AxiosResponse<ResInterface>;
export const putRecommend = (
	data: InterfaceRecommendItem
): Promise<DeleteRecommendResInterface> =>
	put(`${DeleteRecommendPath}/${data.id}`, data);

// 获取用户推荐列表
const UserRecommendListPath = '/api/v1/user/recommend/list';
type InterfaceRecommendListRes = AxiosResponse<ResDataRecommendListInterface>;
export const getRecommendList = (
	data: GlobalGetPagesParams
): Promise<InterfaceRecommendListRes> => post(UserRecommendListPath, data);

// 标签请求方法
const TagListPath = '/api/v1/tag/list';
type InterfaceTagListRes = AxiosResponse<ResDataInterface<InterfaceTag[]>>;
export const getTags = (): Promise<InterfaceTagListRes> => get(TagListPath);
