import React from 'react';
import { createBrowserRouter } from 'react-router-dom';
import { Layout } from '@/layout/indexLayout';
import { NoPage } from '@/views/noPage';
import { LazyImportComponent } from '@/components/LazyImportComponent';

// const HomeLazy = React.lazy(() => import('@/views/home'));
const MapLazy = React.lazy(() => import('@/views/map/indexMap'));
const VideoLazy = React.lazy(() => import('@/views/video/indexVideo'));
const VideoPaly = React.lazy(() => import('@/views/video/VideoPlay'));
const BookRecommend = React.lazy(() => import('@/views/book/indexBook'));
const BookDetail = React.lazy(() => import('@/views/book/bookDetail'));
const FreeBook = React.lazy(() => import('@/views/book/bookFree'));
// const Discuss = React.lazy(() => import("@/views/discuss/indexDiscuss"));
const UserInfo = React.lazy(() => import('@/views/user/userInfo'));
const Recommend = React.lazy(() => import('@/views/user/recommend'));
const Login = React.lazy(() => import('@/views/user/login'));
const Register = React.lazy(() => import('@/views/user/register'));

export const router = createBrowserRouter([
	{
		path: '/',
		element: <Layout></Layout>,
		children: [
			// {
			// 	path: '/home',
			// 	element: (
			// 		<LazyImportComponent lazyChildren={HomeLazy}></LazyImportComponent>
			// 	),
			// },
			{
				path: '/',
				element: (
					<LazyImportComponent lazyChildren={MapLazy}></LazyImportComponent>
				),
			},
			{
				path: 'map',
				element: (
					<LazyImportComponent lazyChildren={MapLazy}></LazyImportComponent>
				),
			},
			// {
			// 	path: 'video',
			// 	element: (
			// 		<LazyImportComponent lazyChildren={VideoLazy}></LazyImportComponent>
			// 	),
			// },
			{
				path: 'book',
				element: (
					<LazyImportComponent
						lazyChildren={BookRecommend}
					></LazyImportComponent>
				),
			},
			// {
			//   path: "discuss",
			//   element: (
			//     <LazyImportComponent lazyChildren={Discuss}></LazyImportComponent>
			//   ),
			// },
		],
	},
	// {
	// 	path: 'video',
	// 	element: <Layout></Layout>,
	// 	children: [
	// 		{
	// 			path: ':id',
	// 			element: (
	// 				<LazyImportComponent lazyChildren={VideoPaly}></LazyImportComponent>
	// 			),
	// 		},
	// 	],
	// },
	{
		path: '/book',
		element: <Layout></Layout>,
		children: [
			{
				path: ':id',
				element: (
					<LazyImportComponent lazyChildren={BookDetail}></LazyImportComponent>
				),
			},
			{
				path: 'recommend',
				element: (
					<LazyImportComponent lazyChildren={Recommend}></LazyImportComponent>
				),
			},
			{
				path: 'freeBook',
				element: (
					<LazyImportComponent lazyChildren={FreeBook}></LazyImportComponent>
				),
			},
		],
	},
	{
		path: '/user',
		element: <Layout></Layout>,
		children: [
			// {
			// 	path: 'login',
			// 	element: <Login />,
			// },
			// {
			// 	path: 'register',
			// 	element: <Register />,
			// },
			{
				path: 'userInfo',
				element: <UserInfo />,
			},
			{
				path: 'recommend',
				element: <Recommend />,
			},
		],
	},
	{
		path: '/user/login',
		element: <Login />,
	},
	{
		path: '/user/register',
		element: <Register />,
	},
	{
		path: '*',
		element: <NoPage></NoPage>,
	},
]);
