import { RouterProvider } from 'react-router-dom';
import { router } from './router/indexRouter';
import { Suspense, useEffect } from 'react';
import { ConfigProvider, Spin } from 'antd';
import { Provider } from 'react-redux';
import { store } from '@/store/indexStore';
import { getTags } from '@/api/commonApi';
import zhCN from 'antd/locale/zh_CN';
import './App.less';
import { actionGlobalTags } from './store/storeGlobal';
import { actionMapSelectTags } from '@/store/map';

const Loading = () => {
	return (
		<div
			style={{
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				height: '100vh',
				width: '100vw',
			}}
		>
			<Spin />
		</div>
	);
};

function App() {
	// 获取并设置全局 tags
	useEffect(() => {
		getTags()
			.then((res) => res?.data)
			.then((res) => {
				if (res.code === 0) {
					store.dispatch(actionGlobalTags(res.data));
					// 获取url参数, 设置当前选中的tags
					const searchParams = new URLSearchParams(window.location.search);
					const tags = searchParams.getAll('tag');
					store.dispatch(actionMapSelectTags(tags));
				}
			})
			.catch((err) => {
				console.log('获取tags出错：', err);
			});
	}, []);

	return (
		<ConfigProvider
			locale={zhCN}
			theme={{
				components: {
					// Button: {
					//   colorPrimary: '#00b96b',
					// },
					// Input: {
					//   colorPrimary: '#eb2f96',
					// },
					// Radio: {
					// 	colorPrimary: '#fff',
					// }
				},
				token: {
					colorPrimary: '#fa8c16',
				},
			}}
		>
			<Provider store={store}>
				<Suspense fallback={Loading()}>
					<RouterProvider router={router} />
				</Suspense>
			</Provider>
		</ConfigProvider>
	);
}

export default App;
