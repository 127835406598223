/*
 * 评论相关接口
 */
import { AxiosResponse } from 'axios';
import { del, get, post } from './request';
import { ResDataInterface, ResInterface } from '@/types/api.interface';
import {
	ReqParamsCreateCommentInterface,
	ReqParamsCommentList,
	ResDataCommentListInterface,
	ResDataCommentCountInterface,
} from '@/types/comment/comment.interface';

// 新增
const CreateCommentPath = '/api/v1/comment';
type CreateCommentResInterface = AxiosResponse<ResInterface>;
export const createCommentAPI = (
	data: ReqParamsCreateCommentInterface
): Promise<CreateCommentResInterface> => post(CreateCommentPath, data);

// 获取评论列表
const GetCommentListPath = '/api/v1/comment/list';
type GetCommentListResInterface = AxiosResponse<ResDataCommentListInterface>;
export const getCommentListAPI = (
	data: ReqParamsCommentList
): Promise<GetCommentListResInterface> => post(GetCommentListPath, data);

// 获取用户评论列表
const GetCommentUserListPath = '/api/v1/comment/userList';
export const getCommentUserListAPI = (
	data: ReqParamsCommentList
): Promise<GetCommentListResInterface> => post(GetCommentUserListPath, data);

// 删除评论
const DeleteCommentPath = '/api/v1/comment';
type DeleteCommentResInterface = AxiosResponse<ResInterface>;
export const deleteCommentAPI = (data: {
	id: string;
}): Promise<DeleteCommentResInterface> => del(DeleteCommentPath, data);

// 获取评论数量
const GetCommentCountPath = '/api/v1/comment/count';
type GetCommentCountResInterface = AxiosResponse<ResDataCommentCountInterface>;
export const getCountCommentsAPI = (): Promise<GetCommentCountResInterface> =>
	get(GetCommentCountPath);

// 获取推荐数量
const GetCountRecommendsPath = '/api/v1/user/recommend/count';
type GetCountRecommendsResInterface = AxiosResponse<
	ResDataInterface<{ count: number }>
>;
export const getCountRecommendsAPI =
	(): Promise<GetCountRecommendsResInterface> => get(GetCountRecommendsPath);
