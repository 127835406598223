import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
// import tagReducer from './features/tagStore';
import globalReducer from './storeGlobal';
import userReducer from './storeUser';
import videoReducer from './storeVideo';
import menuReducer from './storeMenu';
import mapReducer from './map/index';
import mapPlayReducer from './map/mapPlay';
import mapDataReducer from './map/data';

export const store = configureStore({
	reducer: {
		// tag: tagReducer,
		global: globalReducer,
		user: userReducer,
		video: videoReducer,
		menu: menuReducer,
		map: mapReducer,
		mapPlay: mapPlayReducer,
		mapData: mapDataReducer,
	},
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type 推导出其 reducer: {tag: tagReducer, user: userReducer,}
export type AppDispatch = typeof store.dispatch;
export type AppThunk<ReturnType = void> = ThunkAction<
	ReturnType,
	RootState,
	unknown,
	Action<string>
>;
